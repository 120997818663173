export default function ElectronChart() {
	return (
		<div style={{ overflow: 'hide' }}>
			<iframe
				style={{ width: '100%', height: '80vh' }}
				seamless
				frameBorder="0"
				scrolling="yes"
				src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRQZ6NReu7cWqXo9dPb90cDjhAOM4U_oljQoUmiI_IVx8KoxKFQGwNp1owgI5kpLNZb8DWuctLC0KfU/pubchart?oid=1873321305&amp;format=interactive"
			></iframe>
		</div>
	);
}
